import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowIcon, SaveIcon } from "../../icons/Icons";

type LanguageSwitcherProps = {
  showOptions: boolean;
  toggleOptions: Function;
};

const LanguageSwitcher = ({ showOptions, toggleOptions }: LanguageSwitcherProps) => {
  const { i18n } = useTranslation();

  const languages = useMemo(() => {
    return [
      {
        name: "en",
        nativeName: "English",
      },
      {
        name: "fr",
        nativeName: "French",
      },
      {
        name: "es",
        nativeName: "Spanish",
      },
    ];
  }, []);

  const [selectedLanguage, setSelectedLanguage] = useState({
    name: "en",
    nativeName: "English",
  });

  useEffect(() => {
    const findLanguage = languages.filter((lang) => lang.name === i18n.language)[0];
    console.log("found", findLanguage);
    if (findLanguage === undefined) {
      setSelectedLanguage({
        name: "en",
        nativeName: "English",
      });
    } else {
      setSelectedLanguage(findLanguage);
    }
  }, [i18n, languages]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    toggleOptions();
  };

  const confirmLanguageChange = () => {
    i18n.changeLanguage(selectedLanguage.name);
  };

  return (
    <div className="language-switcher-dropmenu-wrapper">
      <p>Change Language</p>
      <div className="dev-tools-selector-current-value">
        <div className="value">{selectedLanguage.nativeName}</div>
        <div className="icons">
          <div
            className="drop-icon"
            onClick={() => {
              toggleOptions();
            }}
          >
            <div className={`${showOptions && "active"}`}>
              <ArrowIcon width="20px" height="20px" />
            </div>
          </div>
          <div
            className="save-icon"
            onClick={() => {
              confirmLanguageChange();
            }}
          >
            <SaveIcon width="20px" height="20px" />
          </div>
        </div>
      </div>
      {showOptions && (
        <div className="dev-tools-options-container">
          {languages.map((language) => {
            return (
              <div
                className="api-option"
                onClick={() => {
                  handleLanguageChange(language);
                }}
                key={language.name}
              >
                {language.nativeName}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
