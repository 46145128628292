import { useEffect, useMemo } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const GoogleAnalyticsListener = () => {
  const location = useLocation();

  const userData = useSelector((state: any) => state.currentUser.data);
  const currentBrandData = useSelector((state: any) => state.currentBrand.data);
  const userDataIsLoading = useSelector((state: any) => state.currentUser.isLoading);
  const currentBrandIsLoading = useSelector((state: any) => state.currentBrand.isLoading);
  const googleTrackingId = process.env.REACT_APP_GOOGLE_ID as string;

  const doNotTrackUserId = useMemo(() => {
    if (!currentBrandData.domainSettings || currentBrandIsLoading) return false;
    return currentBrandData.domainSettings.doNotTrackUserId;
  }, [currentBrandData, currentBrandIsLoading]);

  const userToken = useMemo(() => {
    if (!userData.userToken || userDataIsLoading) return false;
    return userData.userToken;
  }, [userData, userDataIsLoading]);

  const userIsAdmin = useMemo(() => {
    return userData.admin;
  }, [userData]);

  const isNotLocalHost = useMemo(() => {
    return window.location.origin.indexOf("localhost") === -1;
  }, []);

  // INITIALIZATION only once on first load
  useEffect(() => {
    if (!ReactGA.isInitialized && !userDataIsLoading && !userIsAdmin && isNotLocalHost) {
      const options = doNotTrackUserId ? {} : { user_id: userToken };
      ReactGA.initialize(googleTrackingId, { gaOptions: options });
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [
    userToken,
    doNotTrackUserId,
    googleTrackingId,
    location.pathname,
    userDataIsLoading,
    userIsAdmin,
    isNotLocalHost,
  ]);

  // Tracking every page
  useEffect(() => {
    if (ReactGA.isInitialized && !userDataIsLoading && !userIsAdmin && isNotLocalHost) {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location, userDataIsLoading, userIsAdmin, isNotLocalHost]);

  return null;
};

export default GoogleAnalyticsListener;
