import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../../components/navigation/MainNavigation";
import Table from "../../components/ui/table/Table";
import { fetchCredits } from "../../store/slices/credits";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import Button from "../../components/ui/common/Button";

const CreditUsage = () => {
  const dispatch = useAppDispatch();
  const credits = useSelector((state: any) => state.credits.data);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCredits());
  }, [dispatch]);

  useEffect(() => {
    document.title = "Credit List - Smartocto real-time";
  }, []);

  const columns = useMemo(() => {
    return [
      { title: "name", label: "Brand" },
      { title: "requests", label: "Total requests (current deal)" },
      { title: "totalCredits", label: "Total credits (current deal)" },
      { title: "remainingCredits", label: "Remaining credits" },
      { title: "remainingDays", label: "Remaining days" },
      { title: "actions", label: "" },
    ];
  }, []);

  const dataWithActions = useMemo(() => {
    return credits.map((e) => {
      return {
        ...e,
        sort: {name: e?.name , requests: e?.requests, totalCredits: e?.totalCredits, remainingCredits: e?.remainingCredits, remainingDays: e?.remainingDays},
        actions: (
          <>
            <Button
              // className="circle-button-icon"
              data-testid={`editButton`}
              title="Edit Credits"
              variant="success"
              onClick={() => {
                if (window.location.hash === "") {
                  window.open(`/admin/brands/${e.id}/ai`, "_blank");
                } else {
                  window.open(`/#/admin/brands/${e.id}/ai`, "_blank");
                }
              }}
            >
              Details
            </Button>
          </>
        ),
      };
    });
  }, [credits]);

  return (
    <>
      <MainNavigation />
      <div className="page-container-no-side-nav">
        <h1 className="admin-page-title">Credit usage </h1>
        {/* <Button
          style={{ marginLeft: 40 }}
          title="Edit User"
          variant="success"
          onClick={() => {
            setEditingColumns(true);
          }}
        >
          <EditIcon /> Edit Columns
        </Button> */}
        {/* {!isLoading ? ( */}
        <div className="table-container">
          <Table showRowNumbers={true} rowData={dataWithActions} columnsData={columns} />
        </div>
        {/* ) : ( */}
        {/* <div className="loader-container">
            <CircleLoader />
          </div>
        )} */}
      </div>
    </>
  );
};

export default CreditUsage;
