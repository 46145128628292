import React, { useState, useEffect, useMemo } from "react";
import Table from "../../components/ui/table/Table";
import MainNavigation from "../../components/navigation/MainNavigation";
import { timeStampToDate } from "../../helpers/dateHelper";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { fetchAICredits } from "../../store/slices/aiCredits";
import { CircleLoader } from "../../icons/Loaders";
import useDateTimezoneAdjust from "../../customHooks/useDateTimezoneAdjust";
import DateTimeLong from "../../components/utils/DateTimeLong";

const AICredits = () => {
  const dispatch = useAppDispatch();
  const dateTimezoneAdjust = useDateTimezoneAdjust();

  const credits = useSelector((state: any) => state.aiCredits.data);
  const isLoading = useSelector((state: any) => state.aiCredits.isLoading);

  useEffect(() => {
    document.title = "Admin - Users - Smartocto real-time";
  }, []);

  const columns = useMemo(() => {
    return [
      { title: "type", label: "AI request type" },
      { title: "credits", label: "Credits" },
      { title: "succeed", label: "Succeed" },
      { title: "timestamp", label: "Timestamp", width: "500px" },
      { title: "userName", label: "User" },
    ];
  }, []);

  useEffect(() => {
    dispatch(fetchAICredits());
  }, [dispatch]);

  const getDate = (timestamp) => {
    return dateTimezoneAdjust(Number(timestamp)).toString();
  };

  const dataWithActions = credits.map((e, i) => {
    return {
      ...e,
      sort: {type: e?.type, credits: e?.credits, succeed: e?.succeed, timestamp: e?.timestamp, userName: e?.userName},
      timestamp: <DateTimeLong inputDateTime={getDate(e.timestamp)} />,
    };
  });

  return (
    <>
      <MainNavigation />
      <div className="page-container-no-side-nav">
        <h1 className="admin-page-title">Credits </h1>
        {!isLoading ? (
          <div className="table-container">
            <Table showRowNumbers={true} rowData={dataWithActions} columnsData={columns} />
          </div>
        ) : (
          <div className="loader-container">
            <CircleLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default AICredits;
