import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Input from "../../../components/ui/common/Input";
import Select from "../../ui/select/Select";
import Button from "../../ui/common/Button";
import ToggleSwitch from "../../../components/ui/toggleSwitch/ToggleSwitch";
import { SettingsIcon } from "../../../icons/Icons";
import { FEATURES } from "./Features";
import DomainStatusSettings from "./DomainStatusSettings";
import CopyConfiguration from "./CopyConfiguration";
import Modal from "../../ui/modal/Modal";
import StatisticsSettings from "./StatisticsSettings";
import AiModal from "./submodals/AiModal";
import WavesModal from "./submodals/WavesModal";
import InsightsSettingsModal from "./submodals/InsightsSettingsModal";
import StoriesSettingsModal from "./submodals/StoriesSettingsModal";
import TentaclesSettingsModal from "./submodals/TentaclesSettingsModal";
import VideosModal from "./submodals/VideosModal";
import { createBrand, updateBrand, resetBrandStates } from "../../../store/slices/brands";
import { createCredits, updateCredits, deleteCredits } from "../../../store/slices/credits";
import { timezones } from "../../../constants/timezones";

import { useAppDispatch } from "../../../store";
import "./EditBrandModal.scss";
import "./submodals/SubModalGeneral.scss";

type EditBrandModalProps = {
  brand: any;
  newBrand: boolean;
  onClose: Function;
  brands: any;
};

const EditBrandModal = ({ brand, newBrand, onClose, brands }: EditBrandModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showDomainStatusSettings, setShowDomainStatusSettings] = useState(false);
  const [showCopyConfiguration, setShowCopyConfiguration] = useState(false);
  const [showStatisticsSettings, setShowStatisticsSettings] = useState(false);
  const [subModalOpen, setSubModalOpen] = useState(false);
  const [activeSubModal, setActiveSubModal] = useState("");
  const [hasAttemptedUpdate, setHasAttemptedUpdate] = useState(false);

  const [brandActive, setBrandActive] = useState(brand?.active?.value || false);
  const [brandFeatures, setBrandFeatures] = useState(brand?.features?.value || []);

  const [brandId, setBrandId] = useState(brand?.id?.value);
  const [brandName, setBrandName] = useState(brand?.name?.value);
  const [timezone, setTimezone] = useState(brand?.timezone?.value);
  const [readingDirection] = useState(brand?.readingDirection?.value || brand?.readingDirection?.default);
  const [trackingDays, setTrackingDays] = useState(brand?.trackingDays?.value || brand?.trackingDays?.default);
  const [primaryStoryType, setPrimaryStoryType] = useState(
    brand?.primaryStoryType?.value || brand?.primaryStoryType?.default,
  );

  const [aiSettings, setAiSettings] = useState(brand.aiSettings);
  const [insightsSettings, setInsightsSettings] = useState(brand.insightsSettings);
  const [storiesSettings, setStoriesSettings] = useState(brand.storiesSettings);
  const [tentaclesSettings, setTentaclesSettings] = useState(brand.tentaclesSettings);
  const [videosSettings, setVideosSettings] = useState(brand.videosSettings);
  const [wavesSettings, setWavesSettings] = useState(brand.wavesSettings);
  const [statisticsSettings, setStatisticsSettings] = useState(brand.statisticsSettings);

  const [brandLogoSrc, setBrandLogoSrc] = useState(brand.brandlogo?.value || brand.brandlogo?.default);

  const creatingBrand = useSelector((state: any) => state.brands.creatingBrand);
  const creatingBrandError = useSelector((state: any) => state.brands.creatingBrandError);

  const updatingBrand = useSelector((state: any) => state.brands.updatingBrand);
  const updatingBrandError = useSelector((state: any) => state.brands.updatingBrandError);

  const { subModalId } = useParams();
  const validSubModals = ["insights", "stories", "tentacles", "videos", "waves", "ai"];

  useEffect(() => {
    if (subModalId && validSubModals.includes(subModalId)) {
      setActiveSubModal(subModalId);
      setSubModalOpen(true);
    }
  }, [subModalId]);

  const handleToggleDomainStatus = () => {
    if (showDomainStatusSettings) {
      setShowDomainStatusSettings(false);
    } else {
      setShowDomainStatusSettings(true);
      setShowCopyConfiguration(false);
      setShowStatisticsSettings(false);
    }
  };

  const handleToggleCopyConfiguration = () => {
    if (showCopyConfiguration) {
      setShowCopyConfiguration(false);
    } else {
      setShowDomainStatusSettings(false);
      setShowCopyConfiguration(true);
      setShowStatisticsSettings(false);
    }
  };

  const handleToggleStatistics = () => {
    if (showStatisticsSettings) {
      setShowStatisticsSettings(false);
    } else {
      setShowStatisticsSettings(true);
      setShowDomainStatusSettings(false);
      setShowCopyConfiguration(false);
    }
  };

  const handleSaveAiSettings = (data) => {
    setAiSettings(data);
  };

  const handleSaveAiCredits = (data) => {
    if (data.id === "") {
      dispatch(createCredits(brandId, data));
    } else {
      dispatch(updateCredits(brandId, data.id, data));
    }
  };

  const handleDeleteAiCredits = (creditId) => {
    dispatch(deleteCredits(brandId, creditId));
  };

  const handleSaveInsightsSettings = (data) => {
    setInsightsSettings(data);
  };

  const handleSaveStoriesSettings = (data) => {
    setStoriesSettings(data);
  };

  const handleSaveTentaclessSettings = (data) => {
    setTentaclesSettings(data);
  };

  const handleSaveVideosSettings = (data) => {
    setVideosSettings(data);
  };

  const handleSaveWavesSettings = (data) => {
    setWavesSettings(data);
  };
  const handleEditStaticsSettings = (input, parent, field) => {
    setStatisticsSettings({
      ...statisticsSettings,
      [parent]: {
        ...statisticsSettings[parent],
        [field]: { default: statisticsSettings[parent][field]["default"], value: input },
      },
    });
  };

  const closeSubModal = () => {
    setActiveSubModal("");
    setSubModalOpen(false);
    navigate(`/admin/brands/${brandId}/`);
  };

  const closeModal = () => {
    onClose();
  };

  const currentSubModal = useMemo(() => {
    switch (activeSubModal) {
      case "insights":
        return (
          <InsightsSettingsModal data={insightsSettings} onSave={handleSaveInsightsSettings} onClose={closeSubModal} />
        );
      case "stories":
        return (
          <StoriesSettingsModal data={storiesSettings} onSave={handleSaveStoriesSettings} onClose={closeSubModal} />
        );
      case "tentacles":
        return (
          <TentaclesSettingsModal
            data={tentaclesSettings}
            onSave={handleSaveTentaclessSettings}
            onClose={closeSubModal}
          />
        );
      case "videos":
        return <VideosModal data={videosSettings} onSave={handleSaveVideosSettings} onClose={closeSubModal} />;
      case "waves":
        return <WavesModal data={wavesSettings} onSave={handleSaveWavesSettings} onClose={closeSubModal} />;
      case "ai":
        return (
          <AiModal
            data={aiSettings}
            onSave={handleSaveAiSettings}
            onSaveCredits={handleSaveAiCredits}
            onDeleteCredits={handleDeleteAiCredits}
            onClose={closeSubModal}
          />
        );

      default:
        return <div></div>;
    }
  }, [activeSubModal]);

  const handleFeatureSwitch = useCallback(
    (clickedFeature) => {
      if (brandFeatures.includes(clickedFeature)) {
        setBrandFeatures(
          brandFeatures.filter((feature) => {
            return feature !== clickedFeature;
          }),
        );
      } else {
        setBrandFeatures([...brandFeatures, clickedFeature]);
      }
    },
    [brandFeatures],
  );

  const handleFileUpdate = (e) => {
    // @ts-ignore
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setBrandLogoSrc(reader.result);
    });

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const mapValuesToBaseAttr = (objectToMap) => {
    const newObject = { ...objectToMap };
    for (const key in newObject) {
      if (Object.prototype.hasOwnProperty.call(newObject, key)) {
        // Check if the 'default' property of the current 'newObject' key is of type boolean
        if (typeof newObject[key].default === "boolean") {
          // If true, assign the 'value' property to 'newObject[key]' because the switch was toggled to the state of the value else the default
          newObject[key] = newObject[key].value === true;
        } else {
          // If the 'default' property is not a boolean, assign the 'value' property
          // if it's truthy; otherwise, assign the 'default' property
          newObject[key] = newObject[key].value || newObject[key].default;
        }
      }
    }
    return newObject;
  };

  // get all brand ids except the current brand id
  const allBrandIds = useMemo(() => {
    return brands
      .filter((brand) => {
        return brand.id.value !== brandId;
      })
      .map((brand) => {
        return brand.id.value;
      });
  }, [brands]);

  const handleSaveBrand = useCallback(async () => {
    const dataToSave = {
      active: brandActive,
      aiSettings: mapValuesToBaseAttr(aiSettings),
      apiToken: brand.apiToken.value || brand.apiToken.default,
      brandlogo: brandLogoSrc,
      features: brandFeatures,
      insightsSettings: mapValuesToBaseAttr(insightsSettings),
      name: brandName,
      primaryStoryType: primaryStoryType,
      readingDirection: readingDirection,
      storiesSettings: mapValuesToBaseAttr(storiesSettings), // storyKpiPercentile ..??
      statisticsSettings: {
        atInternetAuth: mapValuesToBaseAttr(statisticsSettings.atInternetAuth),
        gaAuth: mapValuesToBaseAttr(statisticsSettings.gaAuth),
      },
      tentaclesSettings: {
        abTestSettings: mapValuesToBaseAttr(tentaclesSettings.abTestSettings),
        classifications: mapValuesToBaseAttr(tentaclesSettings.classifications),
        settings: mapValuesToBaseAttr(tentaclesSettings.settings),
        tentaclesInfo: mapValuesToBaseAttr(tentaclesSettings.tentaclesInfo),
      },
      timezone: timezone,
      trackingDays: trackingDays,
      videosSettings: mapValuesToBaseAttr(videosSettings),
      wavesSettings: mapValuesToBaseAttr(wavesSettings),
    };

    try {
      if (newBrand) {
        dataToSave["id"] = brandId;
        await dispatch(createBrand(dataToSave));
      } else {
        await dispatch(updateBrand(brandId, dataToSave));
      }
      setHasAttemptedUpdate(true);
    } catch (err) {
      console.log(err);
    }
  }, [
    brandActive,
    aiSettings,
    brand,
    brandFeatures,
    brandId,
    brandLogoSrc,
    brandName,
    dispatch,
    insightsSettings,
    primaryStoryType,
    readingDirection,
    storiesSettings,
    statisticsSettings,
    tentaclesSettings,
    timezone,
    trackingDays,
    videosSettings,
    wavesSettings,
  ]);

  useEffect(() => {
    if (hasAttemptedUpdate && (!updatingBrand || !creatingBrand)) {
      if (updatingBrandError === null && creatingBrandError === null) {
        closeModal();
      }
    }
  }, [hasAttemptedUpdate, creatingBrand, creatingBrandError, updatingBrand, updatingBrandError]);

  useEffect(() => {
    // Reset the brand update error state when the modal is opened
    dispatch(resetBrandStates());
  }, [dispatch]);

  return (
    <div className="edit-brand-modal-wrapper">
      <Modal
        isOpen={subModalOpen}
        onClose={() => {
          closeSubModal();
        }}
      >
        {currentSubModal}
      </Modal>

      <h1 className="modal-title">{brand?.id?.value ? "Edit" : "Add"} Brand</h1>

      <div className="edit-brand-container">
        <div className="edit-brand-details">
          <div className="brand-active-switch-container">
            <span className="toggle-switch-label"> Brand Active</span>
            <ToggleSwitch
              checked={brandActive}
              onChange={(e) => {
                setBrandActive(e);
              }}
            />
          </div>
          <Input
            id="id"
            label="Brand Id"
            value={brandId}
            separateLines
            fullWidth
            disabled={!newBrand}
            onChange={(e) => {
              if (!newBrand) return false;
              const value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
              if (value) {
                setBrandId(value.toLowerCase());
              } else {
                setBrandId("");
              }
            }}
          />
          {!newBrand && (
            <Input
              id="token"
              label="API token"
              value={brand?.apiToken?.value || brand?.apiToken?.default}
              onChange={() => {}}
              separateLines
              fullWidth
              readOnly
              disabled
            />
          )}
          <Input
            id="name"
            label="Brand name"
            value={brandName}
            onChange={(e) => {
              setBrandName(e.target.value);
            }}
            separateLines
            fullWidth
          />

          <div className="drop-select">
            <label htmlFor="timezone">Timezone</label>
            <Select
              search
              fullWidth
              options={timezones}
              selectedOption={timezone}
              setSelectedOption={(e) => {
                setTimezone(e);
              }}
              styling={{
                selectOptionsContainerClassName: "timezone-options-dropdown",
              }}
            />
          </div>
          <Input
            id="readingDirection"
            label="Reading Direction"
            value={readingDirection === "ltr" ? "Left to right" : "Right to left"}
            onChange={() => {}}
            separateLines
            fullWidth
            readOnly
            disabled
          />
          <Input
            id="trackingDays"
            type="number"
            label="Tracking Days"
            value={trackingDays}
            onChange={(e) => {
              setTrackingDays(e.target.valueAsNumber);
            }}
            separateLines
            fullWidth
          />
          <Input
            id="primaryStoryType"
            label="Primary story type"
            value={primaryStoryType}
            onChange={(e) => {
              setPrimaryStoryType(e);
            }}
            separateLines
            fullWidth
          />
        </div>
        <div className="brand-features">
          <h3>Features</h3>
          <div className="brand-features-container">
            <ul className="features-list">
              {FEATURES.map((feature) => {
                return (
                  <li className="feature-item" key={feature.name}>
                    <div className="feature-name">{feature.name.toLocaleLowerCase()}</div>
                    <div className="feature-toggle">
                      <ToggleSwitch
                        checked={brandFeatures.includes(feature.name)}
                        disabled={false}
                        onChange={() => {
                          handleFeatureSwitch(feature.name);
                        }}
                      />
                    </div>
                    <div className="feature-settings-icon">
                      {feature.hasSettings ? (
                        // @ts-ignore
                        <SettingsIcon
                          color={brandFeatures.includes(feature.name) ? "#1abcae" : "gray"}
                          width="30"
                          onClick={() => {
                            if (!brandFeatures.includes(feature.name)) return false;
                            navigate(`/admin/brands/${brandId}/${feature.name}`);
                          }}
                        />
                      ) : (
                        <div className="height-corrector"></div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="other-settings">
          <div className="brand-logo-wrapper">
            <div className="brand-logo-container">
              <img src={brandLogoSrc} alt={`${brandName}_logo`} />
            </div>
            <div className="select-logo">
              <input type="file" id="brand-logo" onChange={handleFileUpdate} />

              <label htmlFor="brand-logo">Select new logo</label>
            </div>
          </div>

          <StatisticsSettings
            showStatisticsSettings={showStatisticsSettings}
            handleToggleStatistics={handleToggleStatistics}
            onInputChange={handleEditStaticsSettings}
            data={statisticsSettings}
          />
          <CopyConfiguration
            showCopyConfiguration={showCopyConfiguration}
            handleToggleCopyConfiguration={handleToggleCopyConfiguration}
            allBrandIds={allBrandIds}
          />
        </div>
      </div>
      <div className="sub-modal-error">
        {updatingBrandError && (
          <div className="error-message">
            <strong>Failed updating brand:</strong> <code>{updatingBrandError.message}</code>
          </div>
        )}
        {creatingBrandError && (
          <div className="error-message">
            <strong>Failed creating brand:</strong> <code>{creatingBrandError.message}</code>
          </div>
        )}
      </div>
      <div className="sub-modal-actions">
        <Button variant="danger" onClick={closeModal}>
          Cancel
        </Button>
        <Button.Feedback
          variant="success"
          onClick={() => {
            handleSaveBrand();
          }}
          disabled={brandName === "" || brandId === ""}
        >
          OK
        </Button.Feedback>
      </div>
    </div>
  );
};

export default EditBrandModal;
