import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: any;
  isLoading: boolean;
  error: Object | null;

  isUpdatingUser: boolean;
  postingError: Object | null;
  isPosting: boolean;
};

const INITIAL_STATE = {
  data: [],
  isLoading: true,
  error: null,

  isUpdatingUser: false,
  postingError: null,
  isPosting: false,
};

const currentUser = createSlice({
  name: "currentUser",
  initialState: INITIAL_STATE,
  reducers: {
    fetchCurrentUserStart: (state: State) => {
      state.isLoading = true;
    },
    fetchCurrentUserSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchCurrentUserFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    clearCurrentUserData: (state: State) => {
      state = INITIAL_STATE;
    },

    updateSubscriptionsStart: (state: State) => {
      state.isPosting = true;
      state.postingError = null;
    },
    updateSubscriptionsSuccess: (state: State, action) => {
      state.isPosting = false;
      const subscriptionsData = Object.assign({}, state.data.subscriptions, action.payload);

      state.data = Object.assign({}, state.data, {
        subscriptions: subscriptionsData,
      });
    },
    updateSubscriptionsFailed: (state: State, action) => {
      state.isPosting = false;
      state.postingError = action.payload;
    },

    updateCurrentUserStart: (state: State) => {
      state.isUpdatingUser = true;
    },
    updateCurrentUserSuccess: (state: State) => {
      state.isUpdatingUser = false;
    },
    updateCurrentUserFailed: (state: State, action) => {
      state.isUpdatingUser = false;
    },
  },
});

export default currentUser.reducer;

const {
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  fetchCurrentUserFailed,
  clearCurrentUserData,
  updateSubscriptionsStart,
  updateSubscriptionsSuccess,
  updateSubscriptionsFailed,
  updateCurrentUserStart,
  updateCurrentUserSuccess,
  updateCurrentUserFailed,
} = currentUser.actions;

export const fetchCurrentUser = () => async (dispatch: Function) => {
  try {
    dispatch(fetchCurrentUserStart());
    const api = new Api();
    const result = await api.getCurrentUser();
    dispatch(fetchCurrentUserSuccess(result));
  } catch (err) {
    dispatch(fetchCurrentUserFailed(err));
  }
};

export const updateSubscriptions = (subscriptionsData) => async (dispatch: Function) => {
  try {
    dispatch(updateSubscriptionsStart());
    const api = new Api();
    await api.postUpdateProfile(subscriptionsData);
    dispatch(updateSubscriptionsSuccess(subscriptionsData));
  } catch (err) {
    dispatch(updateSubscriptionsFailed(err));
    throw err;
  }
};

export const updateCurrentUser = (user) => async (dispatch: Function) => {
  try {
    dispatch(updateCurrentUserStart());
    const api = new Api();
    await api.postUpdateCurrentUser(user);
    dispatch(updateCurrentUserSuccess());
  } catch (err) {
    dispatch(updateCurrentUserFailed(err));
    throw err;
  }
};

export const clearUserData = () => (dispatch: Function) => {
  dispatch(clearCurrentUserData());
};
