import React, { useEffect, useMemo, useState } from "react";
import Input from "../../ui/common/Input";
import Button from "../../ui/common/Button";
import TextArea from "../../ui/common/TextArea";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { fetchBrands } from "../../../store/slices/brands";
import "./EditUmbrellaBrandsModal.scss";
import { updateUmbrellaBrand } from "../../../store/slices/umbrellaBrands";

type EditUmbrellaBrandsModalProps = {
  data: any;
  handleClose: Function;
  newUmbrellaBrand: boolean;
};

const EditUmbrellaBrandsModal = ({ data, handleClose, newUmbrellaBrand }: EditUmbrellaBrandsModalProps) => {
  const dispatch = useAppDispatch();
  const brands = useSelector((state: any) => state.brands.data);

  const [id, setId] = useState(data.id);
  const [name, setName] = useState(data.name);
  const [genericSectionList, setGenericSectionList] = useState(JSON.stringify(data.genericSectionlist, null, 2));
  const [availableBrands, setAvailableBrands] = useState<any>([]);
  const [activeBrands, setActiveBrands] = useState<any>([]);
  const [sectionBlackList, setSectionBlackList] = useState(JSON.stringify(data.sectionBlacklist, null, 2));
  const [relativeProportions, setRelativeProportions] = useState(JSON.stringify(data.relativeProportions, null, 2));
  const [actionDescription, setActionDescription] = useState("");

  useEffect(() => {
    dispatch(fetchBrands());
  }, [dispatch]);

  useEffect(() => {
    if (brands.length === 0 || brands === undefined) return;
    // set initial active brands
    let initialActiveBrands = [];
    let initialAvailableBrands = [...brands];
    if (!data.brands) return;
    data.brands.forEach((brand) => {
      const foundActiveBrand = brands.filter((e) => {
        return e.id === brand;
      })[0];
      //@ts-ignore
      initialActiveBrands.push(foundActiveBrand);
    });

    data.brands.forEach((brand) => {
      initialAvailableBrands = brands.filter((e) => {
        return e.id !== brand;
      });
    });
    setActiveBrands(initialActiveBrands);
    setAvailableBrands(initialAvailableBrands);
  }, [brands, data]);

  const removeSelectedBrand = (brand) => {
    setActiveBrands(activeBrands.filter((e) => e.id !== brand.id));
    setAvailableBrands([...availableBrands, brand]);
    setActionDescription(`Moved "${brand.name}" to inactive brands`);
  };

  const addSelectedBrand = (brand) => {
    setAvailableBrands([...availableBrands.filter((e) => e.id !== brand.id)]);
    setActiveBrands([...activeBrands, brand]);
    setActionDescription(`Moved "${brand.name}" to active brands`);
  };

  const sortBrands = (brands) => {
    return brands.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  const handleSaveUmbrellaBrand = () => {
    const payload = {
      brands: activeBrands,
      genericSectionList,
      id,
      name,
      relativeProportions,
      sectionsIgnoreList: sectionBlackList,
    };
    dispatch(updateUmbrellaBrand(id, payload));
  };

  return (
    <div className="umbrella-modal-wrapper">
      <h1 className="umbrella-modal-title">Edit Umbrella Brand</h1>
      <div className="umbrella-modal-container">
        <div className="column">
          <Input label="Id" value={id} disabled separateLines fullWidth />
          <Input
            label="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled
            separateLines
            fullWidth
          />
          <div className="brands-selector-container">
            <div className="all-brands-wrapper">
              <p>Inactive Brands</p>
              <div className="all-brands-container">
                {sortBrands(availableBrands).map((e) => {
                  return (
                    <div
                      className="brand-label"
                      onClick={() => {
                        addSelectedBrand(e);
                      }}
                      key={e.id}
                    >
                      {e?.name}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="selected-brands-wrapper">
              <p>Active Brands</p>
              <div className="selected-brands-container">
                {sortBrands(activeBrands).map((e) => {
                  return (
                    <div
                      className="brand-label"
                      onClick={() => {
                        removeSelectedBrand(e);
                      }}
                      key={e?.id}
                    >
                      {e?.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="action-description">{actionDescription}</div>
        </div>
        <div className="column">
          <TextArea
            label="Generic section list"
            value={genericSectionList}
            onChange={(e) => {
              setGenericSectionList(e.target.value);
            }}
            className="textarea-code-editor"
          />
          <TextArea
            label="Section Blacklist"
            value={sectionBlackList}
            onChange={(e) => {
              setSectionBlackList(e.target.value);
            }}
            className="textarea-code-editor"
          />
          <TextArea
            label="Relative Proportions"
            value={relativeProportions}
            onChange={(e) => {
              setRelativeProportions(e.target.value);
            }}
            className="textarea-code-editor"
          />
        </div>
      </div>
      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            handleSaveUmbrellaBrand();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default EditUmbrellaBrandsModal;
