import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  isLoading: boolean;
  data: Array<Object>;
  error: Object | null;

  updatingUmbrellaBrand: boolean;
  deletingUmbrellaBrand: boolean;
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: null,

  updatingUmbrellaBrand: false,
  deletingUmbrellaBrand: false,
};

const umbrellaBrands = createSlice({
  name: "umbrellaBrands",
  initialState: INITIAL_STATE,
  reducers: {
    fetchUmbrellaBrandsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchUmbrellaBrandsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchUmbrellaBrandsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateUmbrellaBrandStart: (state: State) => {
      state.updatingUmbrellaBrand = true;
    },
    updateUmbrellaBrandSuccess: (state) => {
      state.updatingUmbrellaBrand = false;
    },
    updateUmbrellaBrandFailed: (state: State, action) => {
      state.updatingUmbrellaBrand = false;
    },

    deleteUmbrellaBrandStart: (state: State) => {
      state.deletingUmbrellaBrand = true;
    },
    deleteUmbrellaBrandSuccess: (state) => {
      state.deletingUmbrellaBrand = false;
    },
    deleteUmbrellaBrandFailed: (state: State, action) => {
      state.deletingUmbrellaBrand = false;
    },
  },
});

export default umbrellaBrands.reducer;

const {
  fetchUmbrellaBrandsStart,
  fetchUmbrellaBrandsSuccess,
  fetchUmbrellaBrandsFailed,
  updateUmbrellaBrandStart,
  updateUmbrellaBrandSuccess,
  updateUmbrellaBrandFailed,
  deleteUmbrellaBrandStart,
  deleteUmbrellaBrandSuccess,
  deleteUmbrellaBrandFailed,
} = umbrellaBrands.actions;

export const fetchUmbrellaBrands = () => async (dispatch: Function) => {
  try {
    dispatch(fetchUmbrellaBrandsStart());
    const api = new Api();
    const result = await api.getUmbrellaBrands();
    dispatch(fetchUmbrellaBrandsSuccess(result));
  } catch (err) {
    dispatch(fetchUmbrellaBrandsFailed(err));
  }
};

export const updateUmbrellaBrand = (brandId: string, payload: any) => async (dispatch: Function) => {
  try {
    dispatch(updateUmbrellaBrandStart());
    const api = new Api();
    const result = await api.postUpdateUmbrellaBrand(brandId, payload);
    dispatch(updateUmbrellaBrandSuccess(result));
  } catch (err) {
    dispatch(updateUmbrellaBrandFailed(err));
  }
};

export const deleteUmbrellaBrand = (brandId) => async (dispatch: Function) => {
  try {
    dispatch(deleteUmbrellaBrandStart());
    const api = new Api();
    const result = await api.deleteNotifier(brandId);
    dispatch(deleteUmbrellaBrandSuccess(result));
  } catch (err) {
    dispatch(deleteUmbrellaBrandFailed(err));
  }
};
