import React, { useState } from "react";
import Button from "../ui/common/Button";
import Input from "../ui/common/Input";
import QuestionMarkIcon from "../../icons/QuestionMarkIcon";
import ToolTip from "../ui/common/ToolTip";
import "./ProfileNotifications.scss";
import { useAppDispatch } from "../../store";
import { updateSubscriptions } from "../../store/slices/currentUser";
import { useSelector } from "react-redux";
import useIsFeatureAllowed from "../../customHooks/useIsFeatureAllowed";

type ProfileNotificationsProps = {
  user: any;
  openNotificationsModal: Function;
};

type BrandState = {
  currentBrand: {
    isLoading: boolean;
    error: Object | null;
    data: {
      features: Array<string>;
      domain: string;
    } | null;
  };
};

type NotificationErrors = {
  googleChatNotifications?: string;
  teamsNotifications?: string;
  slackNotifications?: string;
};

const ProfileNotifications = ({ user, openNotificationsModal }: ProfileNotificationsProps) => {
  const dispatch = useAppDispatch();
  const brandInfo = useSelector((state: BrandState) => state.currentBrand.data);
  const isFeatureAllowed = useIsFeatureAllowed();
  const [googleChatWebhookUrl, setGoogleChatWebhookUrl] = useState(
    user?.subscriptions?.googleChatNotifications?.webhookUrl || "",
  );
  const [teamsWebhookUrl, setTeamsWebhookUrl] = useState(user?.subscriptions?.teamsNotifications?.webhookUrl || "");
  const [slackWebhookUrl, setSlackWebhookUrl] = useState(user?.subscriptions?.slackNotifications?.webhookUrl || "");
  const [slackChannel, setSlackChannel] = useState(user?.subscriptions?.slackNotifications?.channel || "");
  const [errors, setErrors] = useState<NotificationErrors>({});
  const slackUri = `https://slack.com/oauth/v2/authorize?scope=incoming-webhook&user_scope=&redirect_uri=${encodeURIComponent(
    `${process.env.REACT_APP_URL}/api/user/slackAuth/?brandId=${brandInfo?.domain}&userId=${user.user_token}`,
  )}&client_id=2733131765.4915892766977`;

  const activateGoogleChatNotifications = function () {
    if (
      googleChatWebhookUrl !== "" &&
      googleChatWebhookUrl.trim().match(/^https:\/\/chat.googleapis.com\/.*/) !== null
    ) {
      setErrors((err) => ({
        ...err,
        googleChatNotifications: undefined,
      }));

      const subscriptionsData = JSON.parse(JSON.stringify(user.subscriptions));

      if (!subscriptionsData.googleChatNotifications.hasOwnProperty("brands")) {
        subscriptionsData.googleChatNotifications["brands"] = [brandInfo?.domain];
      } else {
        if (subscriptionsData.googleChatNotifications.brands.indexOf(brandInfo?.domain) < 0) {
          subscriptionsData.googleChatNotifications.brands.push(brandInfo?.domain);
        }
      }
      if (!subscriptionsData.googleChatNotifications.hasOwnProperty("notificationTypes")) {
        subscriptionsData.googleChatNotifications["notificationTypes"] = [];
      }

      subscriptionsData.googleChatNotifications.active = true;
      subscriptionsData.googleChatNotifications.webhookUrl = googleChatWebhookUrl;

      dispatch(updateSubscriptions(subscriptionsData));
    } else {
      setErrors((err) => ({
        ...err,
        googleChatNotifications: "Invalid Google Chat url, it should start with: https://chat.googleapis.com",
      }));
    }
  };

  const disableGoogleChatNotifications = function () {
    const subscriptionsData = JSON.parse(JSON.stringify(user.subscriptions));
    subscriptionsData.googleChatNotifications = { active: false, webhookUrl: null };
    setGoogleChatWebhookUrl("");

    dispatch(updateSubscriptions(subscriptionsData));
  };

  const activateMailNotifications = function () {
    const subscriptionsData = JSON.parse(JSON.stringify(user.subscriptions));

    if (!subscriptionsData.mailNotifications.hasOwnProperty("brands")) {
      subscriptionsData.mailNotifications["brands"] = [brandInfo?.domain];
    } else {
      if (subscriptionsData.mailNotifications.brands.indexOf(brandInfo?.domain) < 0) {
        subscriptionsData.mailNotifications.brands.push(brandInfo?.domain);
      }
    }
    if (!subscriptionsData.mailNotifications.hasOwnProperty("notificationTypes")) {
      subscriptionsData.mailNotifications["notificationTypes"] = [];
    }

    subscriptionsData.mailNotifications.active = true;

    dispatch(updateSubscriptions(subscriptionsData));
  };

  const disableMailNotifications = function () {
    const subscriptionsData = JSON.parse(JSON.stringify(user.subscriptions));
    subscriptionsData.mailNotifications = { active: false };

    dispatch(updateSubscriptions(subscriptionsData));
  };

  const activateTeamsNotifications = function () {
    if (teamsWebhookUrl !== "" && teamsWebhookUrl.trim().match(/^https:\/\/chat.googleapis.com\/.*/) !== null) {
      setErrors((err) => ({
        ...err,
        teamsNotifications: undefined,
      }));

      const subscriptionsData = JSON.parse(JSON.stringify(user.subscriptions));

      if (!subscriptionsData.teamsNotifications.hasOwnProperty("brands")) {
        subscriptionsData.teamsNotifications["brands"] = [brandInfo?.domain];
      } else {
        if (subscriptionsData.teamsNotifications.brands.indexOf(brandInfo?.domain) < 0) {
          subscriptionsData.teamsNotifications.brands.push(brandInfo?.domain);
        }
      }
      if (!subscriptionsData.teamsNotifications.hasOwnProperty("notificationTypes")) {
        subscriptionsData.teamsNotifications["notificationTypes"] = [];
      }

      subscriptionsData.teamsNotifications.active = true;
      subscriptionsData.teamsNotifications.webhookUrl = teamsWebhookUrl;

      dispatch(updateSubscriptions(subscriptionsData));
    } else {
      setErrors((err) => ({
        ...err,
        teamsNotifications: "Invalid Teams webhook url, it should start with: https://brand.webhook.office.com",
      }));
    }
  };

  const disableTeamsNotifications = function () {
    const subscriptionsData = JSON.parse(JSON.stringify(user.subscriptions));
    subscriptionsData.teamsNotifications = { active: false };
    setTeamsWebhookUrl("");

    dispatch(updateSubscriptions(subscriptionsData));
  };

  const toggleReportMailsSelection = (subscriptionType: "smartifyDaily" | "smartifyWeekly") => {
    const subscriptionsData = JSON.parse(JSON.stringify(user.subscriptions));

    if (subscriptionsData.reportMails.indexOf(subscriptionType) === -1) {
      subscriptionsData.reportMails.push(subscriptionType);
    } else {
      subscriptionsData.reportMails.splice(subscriptionsData.reportMails.indexOf(subscriptionType), 1);
    }

    dispatch(updateSubscriptions(subscriptionsData));
  };

  const openSlackAuth = () => {
    window.open(slackUri, "_blank");
  };

  const saveSlackNotifications = function () {
    if (
      slackChannel !== "" &&
      slackChannel.trim().match(/^(#|@)/) !== null &&
      slackWebhookUrl !== "" &&
      slackChannel.trim().match(/^https:\/\/hooks.slack.com/) !== null
    ) {
      setErrors((err) => ({
        ...err,
        slackNotifications: undefined,
      }));

      const slackNotifications = {
        active: true,
        channel: slackChannel,
        webhookUrl: slackWebhookUrl,
      };

      dispatch(updateSubscriptions({ slackNotifications: slackNotifications }));
    } else {
      setErrors((err) => ({
        ...err,
        slackNotifications: "A Slack channel starts with a '#' or '@'",
      }));
    }
  };

  const disableSlackNotifications = function () {
    const subscriptionsData = JSON.parse(JSON.stringify(user.subscriptions));
    subscriptionsData.slackNotifications = { active: false, channel: null, webhookUrl: null };
    setSlackWebhookUrl("");
    setSlackChannel("");

    dispatch(updateSubscriptions(subscriptionsData));
  };

  if (!user) {
    return <></>;
  }

  return (
    <div className="profile-notifications-wrapper">
      {user?.subscriptions?.googleChatNotifications?.active === true ? (
        <div className="profile-notification-container">
          <ToolTip
            message={
              "You are currently receiving notifications on the Google Chat channel configured below. To disable this, click on the button below."
            }
          >
            <QuestionMarkIcon />
          </ToolTip>
          <h3>You will receive notifications in Google Chat</h3>
          <div>
            <Input
              className="input"
              fullWidth
              id="google-chat"
              type="text"
              placeholder="Google chat Webhook"
              value={googleChatWebhookUrl}
              disabled
            />
            <Button
              className="toggle-enable-button"
              variant="danger"
              fullWidth={true}
              rounded={false}
              onClick={disableGoogleChatNotifications}
            >
              Disable
            </Button>
          </div>
        </div>
      ) : (
        <div className="profile-notification-container">
          <ToolTip
            position="right"
            message={
              "To receive notifications on a Google Chat channel, please complete the form below. To create such a channel, follow the intructions on https://developers.google.com/chat/how-tos/webhooks."
            }
          >
            <QuestionMarkIcon />
          </ToolTip>
          <h3>Receive notifications in Google Chat</h3>
          <div>
            <Input
              className="input"
              fullWidth
              id="google-chat"
              type="text"
              placeholder="Google chat Webhook"
              value={googleChatWebhookUrl}
              onChange={(e) => {
                const { name, value } = e.target;
                setGoogleChatWebhookUrl(value);
              }}
            />

            {errors?.googleChatNotifications && (
              <div className="validation-message">{errors.googleChatNotifications}</div>
            )}

            <Button
              className="toggle-enable-button"
              variant="default"
              fullWidth={true}
              rounded={false}
              onClick={activateGoogleChatNotifications}
            >
              Enable
            </Button>
          </div>
        </div>
      )}

      <div className="profile-notification-container">
        {user?.subscriptions?.mailNotifications?.active === true ? (
          <>
            <ToolTip
              message={
                "You currently receive notifications at the e-mail address associated with your smartocto user account. Press the button below to disable this."
              }
            >
              <QuestionMarkIcon />
            </ToolTip>
            <h3>You will receive notifications on {user.email}</h3>
            <Button
              className="toggle-enable-button"
              variant="danger"
              rounded={false}
              fullWidth
              onClick={disableMailNotifications}
            >
              Disable
            </Button>
          </>
        ) : (
          <>
            <ToolTip
              message={
                "Click on the button below if you would like to receive notifications at the e-mail address associated with your SmartOcto user account."
              }
            >
              <QuestionMarkIcon />
            </ToolTip>
            <h3>Receive notifications on {user.email}</h3>
            <Button className="toggle-enable-button" rounded={false} fullWidth onClick={activateMailNotifications}>
              Enable
            </Button>
          </>
        )}

        <h3>Configure the subscription on notifications</h3>
        <Button
          className="toggle-enable-button"
          variant="default"
          fullWidth={true}
          rounded={false}
          onClick={() => {
            openNotificationsModal();
          }}
        >
          select notification
        </Button>
      </div>

      {user?.subscriptions?.slackNotifications?.active === true ? (
        <div className="profile-notification-container">
          <ToolTip
            message={
              "You are currently receiving notifications on the Slack channel configured below. Press the button below to disable this."
            }
          >
            <QuestionMarkIcon />
          </ToolTip>
          <h3>You will receive notifications in your Slack channel {user.subscriptions.slackNotifications.channel}</h3>

          <Input
            className="input"
            fullWidth
            id="channel"
            type="text"
            name="channel"
            placeholder="Channel"
            onChange={(e) => {
              const { name, value } = e.target;
              setSlackChannel(value);
            }}
            value={slackChannel}
          />

          <Input
            className="input"
            fullWidth
            id="webhookUrl"
            type="text"
            name="webhookUrl"
            placeholder="Webhook URL"
            onChange={(e) => {
              const { name, value } = e.target;
              setSlackWebhookUrl(value);
            }}
            value={slackWebhookUrl}
          />

          {errors?.slackNotifications && <div className="validation-message">{errors.slackNotifications}</div>}

          <Button
            className="toggle-enable-button"
            fullWidth={true}
            rounded={false}
            variant="default"
            onClick={saveSlackNotifications}
          >
            Save
          </Button>

          <Button
            className="toggle-enable-button"
            fullWidth={true}
            rounded={false}
            variant="danger"
            onClick={disableSlackNotifications}
          >
            Disable
          </Button>
        </div>
      ) : (
        <div className="profile-notification-container">
          <ToolTip
            message={
              "The button below connects with the Slack with which you to configure a channel in which you want to receive the notifictions.The webhook address is automatically created in your user profile."
            }
          >
            <QuestionMarkIcon />
          </ToolTip>
          <h3>Check to receive this notification in Slack</h3>
          <Button
            className="toggle-enable-button"
            fullWidth={true}
            rounded={false}
            variant="default"
            onClick={openSlackAuth}
          >
            <svg xmlns="http://www.w3.org/2000/svg" style={{
              height: "20px", 
              width: "20px",
              marginRight: "12px"
            }}
              viewBox="0 0 122.8 122.8">
              <path
                d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                fill="#e01e5a"></path>
              <path
                d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                fill="#36c5f0"></path>
              <path
                d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                fill="#2eb67d"></path>
              <path
                d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                fill="#ecb22e"></path>
            </svg>
            <span>connect with Slack</span>
          </Button>
        </div>
      )}

      <div className="profile-notification-container">
        {isFeatureAllowed("smartify") && (
          <>
            <ToolTip
              message={
                "Smartify is activated for your account. With the checkboxes below, you can subscribe to one or both of the reports"
              }
            >
              <QuestionMarkIcon />
            </ToolTip>
            <h3>Smartify subscriptions</h3>
            <Input
              type="checkbox"
              id="daily"
              defaultChecked={user?.subscriptions?.reportMails?.indexOf("smartifyDaily") > -1}
              onChange={() => {
                toggleReportMailsSelection("smartifyDaily");
              }}
            />
            <label htmlFor="daily">Daily report</label>
            <Input
              type="checkbox"
              id="weekly"
              defaultChecked={user?.subscriptions?.reportMails?.indexOf("smartifyWeekly") > -1}
              onChange={() => {
                toggleReportMailsSelection("smartifyWeekly");
              }}
            />
            <label htmlFor="weekly">Weekly report</label>
          </>
        )}
        <br /> <br />
        {user?.subscriptions?.teamsNotifications?.active === true ? (
          <>
            <ToolTip
              message={
                "You currently receive notifications on a Microsoft Teams channel. Press the button below to disable this."
              }
            >
              <QuestionMarkIcon />
            </ToolTip>
            <h3>You will receive notifications in Microsoft Teams</h3>
            <Input
              className="input"
              fullWidth
              type="text"
              id="microsoft-teams"
              placeholder="Microsoft Teams Webhook"
              disabled
              onChange={(e) => {
                const { name, value } = e.target;
                setTeamsWebhookUrl(value);
              }}
              value={teamsWebhookUrl}
            />
            <Button
              className="toggle-enable-button"
              fullWidth={true}
              variant="danger"
              rounded={false}
              onClick={disableTeamsNotifications}
            >
              Disable
            </Button>
          </>
        ) : (
          <>
            <ToolTip
              message={
                "To receive notifications in your Microsoft Teams, please complete the form below. To create such a webhook, follow the intructions on https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=dotnet"
              }
            >
              <QuestionMarkIcon />
            </ToolTip>
            <h3>Check to receive this notification in Microsoft Teams</h3>
            <Input
              className="input"
              fullWidth
              type="text"
              id="microsoft-teams"
              value={teamsWebhookUrl}
              onChange={(e) => {
                const { name, value } = e.target;
                setTeamsWebhookUrl(value);
              }}
              placeholder="Microsoft Teams Webhook"
            />

            {errors?.teamsNotifications && <div className="validation-message">{errors.teamsNotifications}</div>}

            <Button
              className="toggle-enable-button"
              fullWidth={true}
              variant="default"
              rounded={false}
              onClick={activateTeamsNotifications}
            >
              Enable
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileNotifications;
