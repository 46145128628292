import React, { useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../ui/common/Input";
import Button from "../../ui/common/Button";
import TextArea from "../../ui/common/TextArea";
import Select from "../../ui/select/Select";
import ToggleSwitch from "../../ui/toggleSwitch/ToggleSwitch";
import cronstrue from "cronstrue";
import cronParser from "cron-parser";
import { useSelector } from "react-redux";
import "./EditNotifiersModal.scss";
import { fetchBrands } from "../../../store/slices/brands";
import { createNotifier, updateNotifier } from "../../../store/slices/notifiers";
import { useAppDispatch } from "../../../store";

const prepareParams = (paramsData) => {
  let params = { ...paramsData };
  for (let key in params) {
    if (params.hasOwnProperty(key)) {
      if (Array.isArray(params[key])) {
        if (params[key].length === 1 && params[key][0].substring(0, params[key][0].length - 1) !== ",") {
          params[key] = params[key][0] + ",";
        } else {
          params[key] = params[key].join(",\n");
        }
      }
      if (typeof params[key] === "object") {
        params[key] = JSON.stringify(params[key], null, 2);
      }
    }
  }
  return params;
};

const EditNotifiersModal = ({ data, newNotifier, closeModal }) => {
  const dispatch = useAppDispatch();
  const brands = useSelector((state: any) => state.brands.data);
  const [notifierId, setNotifierId] = useState(data.id);
  const [name, setName] = useState(data.name);
  const [brandId, setBrandId] = useState(data.brandId || "");
  const [type, setType] = useState(data.type || " ");
  const [filteringTags, setFilteringTags] = useState(data.filteringTags || []);
  const [cronTime, setCronTime] = useState(data.cronTime);
  const [notifierActive, setNotifierActive] = useState(data.active || false);
  const [params, setParams] = useState(prepareParams(data.params));
  const notifierTypes = useSelector((state: any) => state.notifiers.notifierTypes);

  const creatingNotifier = useSelector((state: any) => state.notifiers.creatingNotifier);
  const creatingNotifierError = useSelector((state: any) => state.notifiers.creatingNotifierError);

  const updatingNotifier = useSelector((state: any) => state.notifiers.updatingNotifier);
  const updatingNotifierError = useSelector((state: any) => state.notifiers.updatingNotifierError);

  const [hasAttemptedUpdate, setHasAttemptedUpdate] = useState(false);
  const currentBrand = useSelector((state: any) => state.currentBrand.data);

  useEffect(() => {
    dispatch(fetchBrands());
  }, [dispatch]);

  const humanReadableCron = useMemo(() => {
    try {
      return cronstrue.toString(cronTime);
    } catch {
      return "";
    }
  }, [cronTime]);

  const cronNextIteration = useMemo(() => {
    try {
      return `Next iteration: ${cronParser.parseExpression(cronTime).next().toString()}`;
    } catch {
      return "";
    }
  }, [cronTime]);

  const timezone = useMemo(() => {
    return currentBrand.domainSettings.timezone;
  }, [currentBrand]);

  const brandOptions = useMemo(() => {
    return brands.map((brand) => {
      return brand.id;
    });
  }, [brands]);

  const prepareParamsForSave = (paramsData) => {
    let params = { ...paramsData };
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        const paramType = notifierTypes[type].find((param) => param.field === key)?.type;
        if (params[key] && paramType === "array" && typeof params[key] === "string" && params[key].includes(",")) {
          params[key] = params[key].split(",").map((item) => item.trim()).filter(item => item);
        }

        if (params[key] && paramType === "boolean") {
          params[key] = (params[key] === "true" || params[key] === true);
        }

        if (key === "messageAlternatives") {
          params[key] = JSON.parse(params[key]);
        }
      }
    }
    return params;
  };


  const handleParamValueChange = (value: any, field: string) => {
    setParams({
      ...params,
      [field]: value,
    });
  };

  const handleSaveNotifier = useCallback(async () => {

    // if the last tag of filteringTags contains a comma, remove it
    if (filteringTags.length > 0 && filteringTags[filteringTags.length - 1].substring(filteringTags[filteringTags.length - 1].length - 1) === ",") {
      filteringTags[filteringTags.length - 1] = filteringTags[filteringTags.length - 1].substring(0, filteringTags[filteringTags.length - 1].length - 1);
    }

    const payload = {
      active: notifierActive,
      brandId,
      cronTime,
      filteringTags: filteringTags.filter((tag) => tag), // remove empty tags created by split
      name,
      params: prepareParamsForSave(params),
      type,
    };

    if (newNotifier) {
      await dispatch(createNotifier(brandId, payload));
      setHasAttemptedUpdate(true);
    } else {
      await dispatch(updateNotifier(notifierId, payload));
      setHasAttemptedUpdate(true);
    }
  }, [notifierActive, brandId, cronTime, humanReadableCron, cronNextIteration, filteringTags, name, params, type, notifierId, dispatch, closeModal]);

  useEffect(() => {
    if (hasAttemptedUpdate && !updatingNotifier) {
      if (creatingNotifierError === null && updatingNotifierError === null) {
        closeModal();
      }
    }
  }, [hasAttemptedUpdate, updatingNotifier, updatingNotifierError, creatingNotifier, creatingNotifierError]);

  
  useEffect(() => {
    if (notifierTypes.length > 0 && notifierTypes[type] === undefined && !newNotifier) {
      alert("Unfortunately, we don't have good param information about this notification type. Add the notification type to the endpoint ‘api/admin/notifierTypes’.");
      return;
    }
    // set default filtering tags for new notifier
    if (newNotifier && notifierTypes[type]) {
      setFilteringTags(notifierTypes[type].find((param) => param.field === 'filteringTags')?.default || []);
    }
  }, [notifierTypes, type]);

  const paramsForm = useMemo(() => {
    if (notifierTypes[type] === undefined) {
      return "";
    }
    // remove filteringTags from params form
    let notifierTypeFields = notifierTypes[type];
    const hasFilteringTags = notifierTypes[type].find((param) => param.field === 'filteringTags');
    if (hasFilteringTags) {
      notifierTypeFields = notifierTypeFields.filter((param) => param.field !== 'filteringTags');
    }

    return (
      <div className="params-container">
        {notifierTypeFields.map((param, key) => {
          if (newNotifier && param.default !== undefined) {
            params[param.field] = param.default
          }

          return (
            <div className="param-container" key={key}>
              <p>{param.field}</p>
              {param.type === "boolean" ? (
                <ToggleSwitch
                  checked={params[param.field] === true}
                  onChange={(e) => {
                    handleParamValueChange(e, param.field);
                  }}
                />
              ) : param.type === "array" || param.type === "object" ? (
                <TextArea
                  value={params[param.field]}
                  placeholder={param.default || ""}
                  onChange={(e) => {
                    handleParamValueChange(e.target.value, param.field);
                  }}
                />
              ) : (
                <Input
                  value={params[param.field]}
                  placeholder={param.default || ""}
                  type={param.type}
                  onChange={(e) => {
                    handleParamValueChange(e.target.value, param.field);
                  }}
                  fullWidth
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }, [notifierTypes, type, params]);

  const shouldShowParams = notifierTypes[type]?.length > 0;
  return (
    <div className="notifiers-modal-wrapper">
      <h1 className="notifiers-modal-title">{`${newNotifier ? 'Add new' : 'Edit'} notifier`}</h1>
      <div className="notifiers-modal-container">
        <div className={["column", `${shouldShowParams ? "narrow" : "wide"}`].join(" ")}>
          <Input label="Raw Id" value={notifierId} disabled separateLines fullWidth />
          <Input
            label="Name"
            value={name || ""}
            onChange={(e) => {
              setName(e.target.value);
            }}
            separateLines
            fullWidth
          />
          <div className="drop-select">
            <label htmlFor="type">Type</label>
            <Select
              options={Object.keys(notifierTypes).sort()}
              disabled={!newNotifier}
              selectedOption={type}
              setSelectedOption={(e) => {
                setType(e);
              }}
              styling={{
                selectOptionsContainerClassName: "types-options-dropdown",
              }}
              fullWidth
              search
            />
          </div>

          <div className="drop-select">
            <label htmlFor="type">Brand Id</label>
            <Select
              options={brandOptions}
              disabled={true}
              selectedOption={brandId}
              setSelectedOption={(e) => {
                setBrandId(e);
              }}
              styling={{
                selectOptionsContainerClassName: "brand-options-dropdown",
              }}
              fullWidth
            />
          </div>
        </div>
        <div className={["column", `${shouldShowParams ? "narrow" : "wide"}`].join(" ")}>
          <p>
            Filtering Tags <small>for a single item, please add a ',' at the end of the line</small>
          </p>

          <TextArea
            value={filteringTags ? filteringTags.join(",\n") : ""}
            onChange={(e) => {
              const tags = e.target.value.split(",\n");
              setFilteringTags(tags);
            }}
            className="filtering-tags-textarea"
          />

          <p>CronTime (based on {timezone} timezone)</p>
          <p>{humanReadableCron}</p>
          <p>{cronNextIteration}</p>

          <Input
            label=""
            value={cronTime}
            onChange={(e) => {
              setCronTime(e.target.value);
            }}
            fullWidth
          />

          <div className="notifier-active-container">

            <ToggleSwitch
              checked={notifierActive}
              onChange={(e) => {
                setNotifierActive(e === true);
              }}
            />{" "}
            <span className="notifier-active-label">Active</span>

          </div>
        </div>
        {shouldShowParams && (
          <div className="column narrow">
            <div className="params-wrapper">
              <p>Params</p>
              {paramsForm}
            </div>
          </div>
        )}
      </div>
      <div className="sub-modal-error">
        {updatingNotifierError && (
          <div className="error-message">
            <strong>Failed updating notifier:</strong> <code>{updatingNotifierError.message}</code>
          </div>
        )}
        {creatingNotifierError && (
          <div className="error-message">
            <strong>Failed creating notifier:</strong> <code>{creatingNotifierError.message}</code>
          </div>
        )}
      </div>
      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          disabled={!name || !brandId || !type || !cronTime}
          onClick={() => {
            handleSaveNotifier();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default EditNotifiersModal;
