import { createSlice } from "@reduxjs/toolkit";
import { asyncLogout, loginViaAuthToken } from "./auth";

import Api from "../api/Api";

type State = {
  isLoading: boolean;
  error: Object | null;
  data: Object | null;
};

const currentBrand = createSlice({
  name: "currentBrand",
  initialState: {
    isLoading: true,
    error: null,
    data: {},
  },
  reducers: {
    fetchCurrentBrandStart: (state: State) => {
      state.isLoading = true;
    },
    fetchCurrentBrandSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchCurrentBrandFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default currentBrand.reducer;

const { fetchCurrentBrandStart, fetchCurrentBrandSuccess, fetchCurrentBrandFailed } = currentBrand.actions;

export const fetchCurrentBrand = (authToken?: string, navigate?: Function) => async (dispatch: Function) => {
  try {
    dispatch(fetchCurrentBrandStart());
    const api = new Api();
    const result = await api.getCurrentBrand(authToken);

    if (!result.loggedin) {
      dispatch(asyncLogout());
    } else {
      const hash = window.location.hash;
      const queryString = hash.split("?")[1];
      const urlParams = new URLSearchParams(queryString);
      const authValue = urlParams.get("auth");
      if (authValue) {
        dispatch(loginViaAuthToken(authValue));
      }
      dispatch(fetchCurrentBrandSuccess(result));
    }
  } catch (err) {
    dispatch(asyncLogout(navigate));
  }
};
